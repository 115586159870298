.footer {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    padding-top: 5rem;
    padding-bottom: 2rem;
}

.logo-container p {
    margin-top: 3rem;
    font-size: 12px;
    color: var(--color-text-light);
}


.links-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
}

.links-container a {
    color: var(--color-text-light);
}

.links-container a:hover {
    color: var(--color-cta);
}

.social-links {
    margin-top: 1.5rem;
}

.social-links a img {
    max-width: 4rem;
    padding: 0 1rem;
}

.social-links a img:hover {
    transform: scale(1.2);
}

@media (max-width: 650px) {
    .footer {
        flex-direction: column-reverse;
    }

    .links-container {
        align-items: center;
        padding-bottom: 1rem;
    }

    .links-container a {
        padding: 0.5rem 0;
    }

    .logo-container img {
        align-items: center;
        width: 100%;
        padding: 0 1rem;
        padding-top: 2rem;
    }

    .logo-container p {
        text-align: center;
        margin-top: 1rem;
    }
}




.container {
    position: relative;
}

.hero-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 2rem;
    z-index: 2;
    position: relative;
}

.mountains {
    position: absolute;
    width: 100%;
    overflow: hidden;
    margin-top: -45dvh;
}

.hero-wrapper h1 {
    color: var(--color-text-light);
    font-size: 4rem;
    text-align: left;
    width: 80%;
}

.hero-wrapper p {
    color: var(--color-text-light);
    text-align: left;
    width: 35%;
    margin-top: -0.75rem;
    line-height: 1.6rem;
}

.button-container {
    display: flex;
    flex-direction: row;
    gap: 4rem;
    margin-top: 2rem;
}

.contact-btn {
    display: flex;
    padding: 1.5rem 3.5rem;
    border-radius: 0.5rem;
    border: none;
    font-weight: 700;
    background: linear-gradient(180deg, #16D476 0%, #0D908C 90.1%);
    font-family: var(--font-family-primary);
    font-size: 18px;
    color: var(--color-text-dark);
    cursor: pointer;
    border: 0 solid;
    box-shadow: inset 0 0 20px rgba(255, 255, 255, 0);
    outline-color: var(--color-text-light);
    outline-offset: 0px;
    text-shadow: none;
    transition: all 1250ms cubic-bezier(0.19, 1, 0.22, 1);
}

.contact-btn:hover {
    border: 1px solid;
    box-shadow: inset 0 0 20px rgba(255, 255, 255, .5), 0 0 20px rgba(255, 255, 255, .2);
    outline-color: rgba(255, 255, 255, 0);
    outline-offset: 15px;
    text-shadow: 1px 1px 2px #427388;
}

.services-btn {
    display: flex;
    padding: 1.5rem 3.5rem;
    border-radius: 0.5rem;
    border-style: double;
    border-color: white;
    background-color: transparent;
    font-family: var(--font-family-primary);
    font-size: 18px;
    font-weight: 700;
    color: var(--color-text-light);
    cursor: pointer;
    border: 0 solid;
    box-shadow: inset 0 0 20px rgba(255, 255, 255, 0);
    outline: 1px solid;
    outline-color: var(--color-text-light);
    outline-offset: 0px;
    text-shadow: none;
    transition: all 1250ms cubic-bezier(0.19, 1, 0.22, 1);
}


.services-btn:hover {
    border: 1px solid;
    box-shadow: inset 0 0 20px rgba(255, 255, 255, .5), 0 0 20px rgba(255, 255, 255, .2);
    outline-color: rgba(255, 255, 255, 0);
    outline-offset: 15px;
    text-shadow: 1px 1px 2px #427388;
}

@media (max-width: 1050px) {
    .hero-wrapper {
        margin-top: 1rem;
    }

    .hero-wrapper h1 {
        /* font-size: 42px; */
        /* line-height: 3rem; */
        width: 100%;
    }

    .hero-wrapper p {
        line-height: 1.6rem;
        font-size: 16px;
        margin-top: 0.4rem;
        width: 50%;
    }

    .button-container {
        flex-direction: row;
        gap: 2.5rem;
        margin-top: 2rem;
    }

    .contact-btn,
    .services-btn {
        font-size: 16px;
        padding: 1rem 2rem;
        margin-top: 0.5rem;
    }

    .mountains {
        top: 82vh;
    }
    
}

@media (max-width: 800px) {
    .hero-wrapper {
        margin-top: 1rem;
    }

    .hero-wrapper h1 {
        font-size: 34px;
        /* line-height: 3rem; */
        width: 100%;
    }

    .hero-wrapper p {
        line-height: 1.6rem;
        font-size: 16px;
        margin-top: 0.4rem;
        width: 50%;
    }

    .button-container {
        flex-direction: row;
        gap: 2.5rem;
        margin-top: 2rem;
    }

    .contact-btn,
    .services-btn {
        font-size: 16px;
        padding: 1rem 2rem;
        margin-top: 0.5rem;
    }

    .mountains {
        top: 82vh;
    }
    
}

@media (max-width: 490px) {
    .hero-wrapper {
        margin-top: 2rem;
    }

    .hero-wrapper h1 {
        font-size: 28px;
    }

    .hero-wrapper p {
        line-height: 1.4rem;
        font-size: 14px;
        margin-top: 0.4rem;
        width: 60%;
    }

    .profile {
        width: 70%;
    }

    .profile img {
        width: 70%;
    }

    .button-container {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        margin-top: 2rem;
    }

    .contact-btn,
    .services-btn {
        font-size: 12px;
        padding: 0.75rem 1.5rem;
        margin-top: 0.5rem;
    }

    .mountains {
        top: 55rem;
    }
}

.contact-container {
    display: flex;
    padding: 12rem 0;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.contact-info {
    background: linear-gradient(180deg, #0F9F87 0%, #0D918C 100%);
    border-radius: 1rem;
    padding: 6rem 3rem;
    max-height: 36rem;
}

.get-in-touch {
    background: #d4e4df;
    border-radius: 1rem;
    padding: 3.5rem 5rem;
    width: 45rem;
}

.contact-info h3 {
    padding-bottom: 3rem;
    font-weight: 500;
    padding: 0rem 0rem;
    transform: translateY(-2.5rem);
}

.get-in-touch h3 {
    color: var(--color-text-dark);
    font-weight: 500;
}

.contact-link-container {
    display: flex;
    flex-direction: column;
    transform: translateY(-1.5rem);
}

.contact-link {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 0rem 0;
}

.contact-link img {
    width: 2.5rem;
    padding-right: 1rem;
}


.contact-link h4 {
    font-size: 14px;
    font-family: var(--font-family-primary);
    color: var(--color-text-light);
    font-weight: 100;
}


@media (max-width: 1200px) {

    .contact-bg {
        padding: 6rem 0;
    }

    .contact-container {
        flex-direction: column;
    }

    .get-in-touch {
        margin: 1rem 1rem;
        padding: 1rem 1.5rem;
        text-align: center;
        width: 100%;
        max-width: 45rem;
    }

    .get-in-touch h3 {
        margin: 1rem auto;
    }

    .contact-info {
        margin: 1rem 1rem;
        padding: 1.5rem 1.5rem;
        text-align: center;
        width: 100%;
        max-width: 45rem;
    }

    .contact-info h3 {
        transform: translateY(-0.4rem);
    }

    .contact-link-container {
        margin: 2rem 1rem;
    }

}

/* @media (max-width: 490px) {

    .contact-bg {
        padding: 6rem 0;
    }

    .contact-container {
        flex-direction: column;
    }

    .get-in-touch {
        padding: 1.5rem 1.5rem;
        text-align: center;
    }

    .get-in-touch h3 {
        margin: 1rem auto;
    }

    .contact-info {
        padding: 0.5rem 1rem;
        text-align: center;
        border-top-left-radius: 0.4rem;
        border-top-right-radius: 0.4rem;
    }

    .contact-link-container {
        margin: 2rem 1rem;
    }

} */
h2 {
  font-size: 40px;
  color: var(--color-text-light);
  font-weight: 500;
}

.portfolio {
  padding-top: 55vw;
}

.portfolio-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 4rem auto ;
}

.icon img {
  width: 100%;
  text-align: center;
  padding: 5rem 4rem;
}

@media (max-width: 1250px) {

  .portfolio {
    padding-top: 55vw;
  }
}

@media (max-width: 1200px) {

  .portfolio {
    padding-top: 65vw;
  }

  .portfolio-icons {
    flex-direction: column;
    width: 100%;
  }
}


@media (max-width: 650px) {

  .portfolio {
    padding-top: 75vw;
  }

  h2 {
    font-size: 32px;
  }

  .portfolio-icons {
    flex-direction: column;
    width: 100%;
  }
}

@media (max-width: 490px) {
  .icon img {
    padding: 3rem 4rem;
  }

  .portfolio-icons{
    margin: 2rem auto;
  }

  .portfolio {
    padding-top: 40vh;
  }
}
body {
  margin: 0;
}

.container {
  --max-width: 1200px;
  --padding: 1.5rem;

  width: min(var(--max-width), 100% - (var(--padding)*2));
  margin-inline: auto;
}

@media screen and (max-width: 650px) {
  .container {
    --max-width: 1200px;
    --padding: 1rem;

    width: min(var(--max-width), 100% - (var(--padding)*2));
    margin-inline: auto;
  }
  
}



h1 {
  font-size: 64px;
  font-family: var(--font-family-primary);
  font-weight: 500;
}

h2 {
  font-family: var(--font-family-primary);
}

p {
  font-family: var(--font-family-secondary);
  font-size: 18px;
}

a {
  font-family: var(--font-family-primary);
  color: var(--color-text);
  text-decoration: none;
}

.bg-gradient {
  width: 100%;
  height: fit-content;
  flex-shrink: 0;
  background: radial-gradient(11.31% 46.91% at 0% 74.38%, rgba(14, 66, 89, 0.20) 0%, rgba(1, 20, 26, 0.20) 100%),
    radial-gradient(21.39% 85.2% at 100% 48.45%, rgba(35, 87, 122, 0.20) 0%, rgba(2, 20, 26, 0.20) 100%),
    radial-gradient(135.28% 62.54% at 17.64% -3.24%, #002B39 0%, #001319 41.82%);

}

.scale-up-center {
  -webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
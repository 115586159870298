.logo {
    /* width: 25rem; */
    font-size: 28px;
    font-family: var(--font-family-logo);
    letter-spacing: -0.1rem;
    word-spacing: -0.5rem;
}

.nav-wrapper {
    padding-top: 1.5rem;
    background-color: transparent;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.link-wrapper {
    border-radius: 3rem;
    height: 3rem;
    width: 25rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.link-wrapper p {
    list-style-type: none;
    text-decoration: none;
    color: var(--color-text-light);
    padding: 0 2rem;
}

.nav-menu {
    margin-left: 1rem;

    display: none;
    position: relative;
}

.nav-menu-container {
    text-align: end;
    background: var(--color-text-light);
    position: absolute;
    right: 0;
    top: 40px;
    margin-top: 1.5rem;
    min-width: 190px;
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}

.nav-menu-container p {
    list-style-type: none;
    text-decoration: none;
    color: var(--color-text-dark);
    padding: 0 2rem;
}

@media screen and (max-width: 700px) {
    .nav-menu {
        display: flex;
        flex-direction: row;
    }

    .link-wrapper {
        display: none;
    }
}
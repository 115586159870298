.who-we-are {
    margin-top: 2rem;
}

.profile-container {
    display: flex;
    justify-content: center;
    flex-direction: row;
    margin: 5rem auto;
    gap: 10%;
}

.profile {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    max-width: 25rem;
}

.profile p {
    line-height: 2rem;
    color: var(--color-text-light);
    text-align: center;
}

h3 {
    font-size: 2rem;
    font-family: var(--font-family-primary);
    color: var(--color-text-light);
    font-weight: 100;
}

@media (max-width: 1200px) {

    .who-we-are {
        margin-top: 5rem;
    }

    .profile-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 5rem;
        margin-top: 4rem;
    }

    .profile p {
        line-height: 1.7rem;
        font-size: 16px;
        margin-top: 0rem;
    }
    
    h3 {
        font-size: 1.5rem;
    }
    
  }



@media (max-width: 490px) {

    .who-we-are {
        margin-top: 5rem;
    }

    .profile-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 5rem;
        margin-top: 4rem;
    }

    .profile p {
        line-height: 1.5rem;
        font-size: 14px;
    }
    
    h3 {
        font-size: 1.5rem;
    }
    
  }
/* Import Assets and Typeface */

@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono&family=Open+Sans&family=Sora:wght@500&display=swap');
:root {
  --font-family-primary: 'Sora', sans-serif;
  --font-family-secondary: 'Open Sans', sans-serif;
  --font-family-logo: 'JetBrains Mono', monospace;

  --color-text-dark: #001319;
  --color-text-light: #DDE9E5;
  --color-cta: #16c3a6;
}



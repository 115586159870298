@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono&family=Open+Sans&family=Sora:wght@500&display=swap');

:root {
  --first-color: #0F9F87;
  --input-color: #0013197a;
  --border-color: #b8babd;
  --normal-font-size: 0.9rem;
  --small-font-size: .7rem;
  --font-family: 'Open Sans', sans-serif;
}

*, ::before, ::after {
  box-sizing: border-box;
}

.contact-form-container {
    width: 35rem;
    padding-bottom: 1rem;
}

.form__div {
  position: relative;
  height: 52px;
  margin-bottom: 1rem;
  transform: translateY(-10px);
}

.form__input {
  width: 100%;
  font-size: var(--normal-font-size);
  border: none;
  border-bottom: 1px solid var(--border-color);
  
  border-radius: 0.2rem;
  /* color: var(--border-color); */
  outline: none;
  padding: 1rem;
  background: none;
  z-index: 1;
  font-family: var(--font-family);
}

.form__label {
  position: absolute;
  left: 0;
  top: 1.3rem;
  padding: 0 0.25rem;
  background-color: #d4e4df;
  color: var(--input-color);
  font-size: var(--normal-font-size);
  font-family: var(--font-family);
  transition: 0.3s;
}

.form__button {
  display: block;
  margin-left: auto;
  padding: 0.75rem 2rem;
  outline: none;
  border: none;
  background-color: var(--first-color);
  color: #fff;
  font-size: var(--normal-font-size);
  border-radius: 0.25rem;
  cursor: pointer;
  transition: 0.3s;
}
.form__button:hover {
  box-shadow: 0 10px 36px rgba(0, 0, 0, 0.15);
}

.submit-btn {
    position: relative;
    padding: 0.5rem 2.5rem;
    margin-top: 2rem;
    border-radius: 0.5rem;
    font-weight: 700;
    background: linear-gradient(180deg, #16D476 0%, #0D908C 90.1%);
    font-family: var(--font-family-primary);
    color: #d4e4df;
    font-size: 16px;
    cursor: pointer;
    border: 0 solid;
    box-shadow: inset 0 0 20px rgba(255, 255, 255, 0);
    transition: all 1250ms cubic-bezier(0.19, 1, 0.22, 1);
}



/*Input focus move up label*/
.form__input:focus + .form__label {
  top: -0.5rem;
  left: 0.8rem;
  color: var(--first-color);
  font-size: var(--small-font-size);
  font-weight: 500;
  z-index: 10;
}

/*Input focus sticky top label*/
.form__input:not(:placeholder-shown).form__input:not(:focus) + .form__label {
  top: -0.5rem;
  left: 0.8rem;
  z-index: 10;
  font-size: var(--small-font-size);
  font-weight: 500;
}

/*Input focus*/
.form__input:focus {
  border: 2px solid var(--first-color);
}

@media (max-width: 490px) {


  .contact-form-container {
    width: 19rem;
    padding-bottom: 1.5rem;
  }

  .form__input {
    display: flex;
    width: 100%;
    height: 100%;
    padding: 1rem;
  }

  .submit-btn {
    margin: 1rem;
}
}